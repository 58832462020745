.App {
  display: flex;
  justify-content: center;
  text-align: center;
}

.logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(35vh);
}

.GoldenWeeks-logo {
  max-height:100%; 
  max-width:100%;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
