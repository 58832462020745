.content-div {
    display: flex;
    justify-content: center;
    text-align: center;
}

.text-container {
    width: 800px;
    max-width: 90vw;
}
